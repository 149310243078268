import React from 'react';

import {GridCard} from 'src/components/editorial';
import {SEOWithQuery} from 'src/components/shared';

import {SanityEditorial, Maybe, SanityAuthor} from 'types/generated';

import {Pagination} from '../../components/shared/Pagination';
import {SupportingContent} from '../editorial/SupportingContent';
import {InsightsNavigation} from '../insightsNavigation';

interface AuthorProps {
  author: SanityAuthor;
  articles: [Maybe<SanityEditorial>];
  limit: number;
  currentPage: number;
  numPages: number;
  location: Location;
  href: string;
}

const AuthorTemplate: React.FC<AuthorProps> = ({author, articles, currentPage, numPages}: AuthorProps) => {
  const Posts = articles.map((item, key) => {
    return (
      <GridCard
        key={key}
        cardColClasses={
          ' col-span-full md:col-span-6 grid grid-cols-2 gap-x-4 items-center md:items-start content-start '
        }
        title={item?.title}
        category={item?.editorialCategory ? item?.editorialCategory.title : item?.category}
        image={item?.heroImage}
        imageClasses={' col-span-full pb-1/2 lg:h-[400px] '}
        contentClasses={' col-span-full mt-5 lg:mt-6 '}
        titleClasses={' type-epsilon md:type-gamma '}
        slug={item?.slug?.current}
        eyebrowClasses={''}
        editorialCategory={item?.editorialCategory}
        isPodcast={item?.isPodcast}
      />
    );
  });
  return (
    <>
      <SEOWithQuery
        title={author.seo?.seoMetaTitle || author.name || undefined}
        description={author?.seo?.seoDescription || undefined}
        image={author?.seo?.seoImage?.asset?.url || undefined}
        siteUrl={undefined}
      />
      <InsightsNavigation bgColor={'bg-white'} />
      <section className="bg-white px-gutter module-spacing" data-module="article-grid-list">
        <div className="wrapper-default flex flex-col mb-12 md:mb-14">
          <h1 className="type-beta text-black mb-4">{author.name}</h1>
          {!!author.bio && <div className="type-sm-p max-w-[780px]">{author.bio}</div>}
        </div>
        <div className="wrapper-default grid grid-cols-4 md:grid-cols-12 md:gap-x-8 grid-spacing items-start">
          {Posts}
          {numPages != 1 && (
            <div className="col-span-full">
              <div className="flex items-center justify-center w-full flex-col md:flex-row">
                <div>
                  <Pagination currentPage={currentPage} numPages={numPages} slug={`authors/${author.slug?.current}`} />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {!!author.listFooterComponents && <SupportingContent supportingContent={author.listFooterComponents} />}
    </>
  );
};

export default AuthorTemplate;
