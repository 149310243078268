import * as React from 'react';

import {SanityAuthor, SanityEditorial, Maybe} from 'types/generated';

import AuthorTemplate from './index';

export default ({
  pageContext,
  location,
}: {
  pageContext: {
    author: SanityAuthor;
    articles: [Maybe<SanityEditorial>];
    limit: number;
    currentPage: number;
    numPages: number;
    href: string;
  };
  location: Location;
}): React.ReactElement => (
  <AuthorTemplate
    author={pageContext.author}
    articles={pageContext.articles}
    currentPage={pageContext.currentPage}
    limit={pageContext.limit}
    numPages={pageContext.numPages}
    location={location}
    href={pageContext.href}
  />
);
